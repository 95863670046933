<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <Breadcrumbs
          :bread-crumbs="breadCrumbs"
          :currentpage="item.Description"
        />
      </b-col>
      <b-col cols="12"
        ><h1>{{ item.Description }}</h1>
      </b-col>
      <b-col xs="12" md="7" lg="8">
        <b-row>
          <b-col sm="12" lg="12">
            <template v-if="loaded">
              <item-image-slider v-if="showSlider" :images="imageCollection" />

              <img
                v-else
                class="d-block item-image mx-auto"
                src="/img/itemFallback.png"
              />
            </template>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" md="5" lg="4">
        <ItemPlanner :item="item" />
      </b-col>
      <b-col cols="12" class="mt-4">
        <div>
          <b-tabs justified content-class="mt-3">
            <b-tab title="Omschrijving" active
              ><div v-html="item.CustomFields.CUST_Notes"></div
            ></b-tab>
            <b-tab v-if="specifications" title="Inhoud">
              <div v-html="specifications"></div>
            </b-tab>
            <b-tab
              v-if="
                (item.ItemAttachments && item.ItemAttachments.length > 0) ||
                  files.length > 0
              "
              title="Handleiding"
              ><item-tab-files
                :rooturl="rootURL"
                :item="item"
                :item-attachments="files"
            /></b-tab>
            <b-tab v-if="item.CustomFields.CUST_YouTubeLink" title="Video"
              ><iframe
                width="100%"
                height="500px"
                :src="
                  `https://www.youtube.com/embed/${item.CustomFields.CUST_YouTubeLink}`
                "
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe
            ></b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
    <item-suggested-items :suggested-items="suggestedItems" />
  </b-container>
</template>

<script>
// @ is an alias to /src

import { getItem, getSuggestedItems } from '@/services/ItemService'
import { generateBreadCrumbs } from '@/services/Breadcrumbs'
import ItemPlanner from './ItemPlanner'
import Breadcrumbs from '@/components/header/BreadCrumbs'
import ItemSuggestedItems from './ItemSuggestedItems'
import ItemImageSlider from './ImageSlider/ItemImageSlider'
import ItemTabFiles from '@/views/Item/ItemTabFiles'

export default {
  name: 'Item',
  components: {
    ItemPlanner,
    Breadcrumbs,
    'item-suggested-items': ItemSuggestedItems,
    'item-tab-files': ItemTabFiles,
    'item-image-slider': ItemImageSlider
  },

  data() {
    return {
      item: { CustomFields: {} },
      breadCrumbs: [{ page: 'index', title: 'Uitleendienst', path: '/' }],
      suggestedItems: [],
      amount: 1,
      loaded: false
    }
  },
  metaInfo() {
    return {
      title: `${this.item.Description ?? ''}`,
      meta: [
        {
          name: `description`,
          content: `${this.stripHtml(this.item.MediumDescription)}`,
          vmid: 'og:meta-description'
        }
      ]
    }
  },
  computed: {
    subCategorySlugFromItem: function() {
      return this.getLastWord(this.item.CategorySlugs)
    },

    specifications: function() {
      if (this.item.CustomFields.CUST_Specifications)
        return this.item.CustomFields.CUST_Specifications
      return this.item.CustomFields.CUST_ItemDetails
    },
    dateEditable: function() {
      return this.$store.getters.cartCount < 1
    },
    imageUrl: function() {
      if (this.item.Images && this.item.Images[0]) {
        return process.env.VUE_APP_URL + this.item.Images[0].ImageUrl
      } else {
        return null
      }
    },
    images: function() {
      if (this.item.Images && this.item.Images[0]) {
        let images = this.item.Images
        return images
      } else {
        return []
      }
    },

    rootURL: function() {
      return process.env.VUE_APP_URL
    },
    imageCollection: function() {
      let images = []

      if (this.item.Images) images = this.item.Images

      if (this.item.Type === 'Composition' && images.length == 0) {
        for (const item of this.item.Items) {
          images = images.concat(item.Images)
        }
      }

      return images
    },
    files: function() {
      let itemAttachments = []
      if (this.item.Type === 'Composition') {
        for (const item of this.item.Items) {
          itemAttachments = itemAttachments.concat(item.ItemAttachments)
        }
      }
      return itemAttachments
    },
    showSlider: function() {
      return (
        this.imageCollection &&
        this.imageCollection != undefined &&
        this.imageCollection[0]
      )
    }
  },
  async created() {
    this.item = await getItem({ itemID: this.$route.params.itemID, slug: true })

    this.loaded = true

    this.breadCrumbs = await generateBreadCrumbs({
      categoryID:
        this.$route.params.categoryID ||
        this.subCategorySlugFromItem ||
        this.item.CategoryIDs,
      item: true
    })

    this.suggestedItems = await getSuggestedItems({
      itemID: this.item.ID,
      slug: true
    })
  },

  methods: {
    getDateByDay({ days, substract = false } = {}) {
      let date = new Date()

      if (!substract) {
        date.setDate(date.getDate() + days)
      } else {
        date.setDate(date.getDate() - days)
      }
      return date
    },
    getLastWord: function(string) {
      if (!string) return ''
      return string
        .replace(/,/g, ' ')
        .replace(/  +/g, ' ')
        .trim()
        .split(' ')
        .splice(-1)
    },
    stripHtml: function(html) {
      var tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  background-color: rgba(247, 247, 247, 1);
  img {
    width: 95%;
  }
  .VueCarousel-inner {
    img {
      width: 150px;
    }
  }
}
h1 {
  font-size: 25px;
}
ul li {
  margin-bottom: 5px;
}
.item-image {
  height: 469px;
  width: auto !important;
}
.VueCarousel {
  display: block;
  text-align: center;
  img.rounded {
    width: 60%;
  }
}

.youtube-button {
  margin-top: 10px;
  margin-left: 64px;
  .btn {
    font-size: 25px;
    padding-bottom: 2px;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.vhd-input {
  display: block;
  width: 100%;
  height: add(1.5em, add(0.75rem, 2px, false));
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
</style>
