<template>
  <b-card title="Verhuur periode" tag="article" class="mb-2 w-100 item-planner">
    <b-card-text>
      <hr />
      <div>
        <warehouse-selector />
        <rental-period v-if="selectedWarehouse" />

        <div v-if="startDate && endDate && item.ID" class="mt-3">
          <div>
            <ItemPlannerItem
              ref="itemPlanner"
              :item-id="item.ID"
              :description="item.Description"
              :type="item.Type"
            />
          </div>
        </div>
        <div v-if="!loggedIn">
          <a v-if="!loggedIn" class="btn btn-block btn-primary" href="/login"
            >Log in om dit artikel te reserveren</a
          >
        </div>
      </div>
    </b-card-text>
  </b-card>
</template>
<script>
import ItemPlannerItem from './ItemPlannerItem'

import WarehouseSelector from '@/components/warehouse/WarehouseSelector'
import { mapGetters } from 'vuex'
import RentalPeriod from '@/components/rental-period/RentalPeriod'

export default {
  components: {
    RentalPeriod,
    WarehouseSelector,
    ItemPlannerItem
  },
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      selectedWarehouse: 'rentalInformation/warehouse',
      startDate: 'rentalInformation/startDate',
      endDate: 'rentalInformation/endDate'
    }),

    loggedIn: function() {
      if (this.$store.state.account?.Email) {
        return true
      }
      return false
    }
  }
}
</script>
<style scoped lang="scss">
.datepicker {
  width: 100%;
}
.nav-tabs {
  width: 100%;
  margin-left: -20px;
}

.partially-available {
  h5,
  p {
    font-family: 'Roboto';
    font-size: 18px;
  }
}
</style>
