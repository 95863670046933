var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.stock && _vm.loaded)?_c('div',{staticClass:"item-planner-single pt-2"},[(_vm.stockItemTitleInfo)?_c('h5',[_vm._v(_vm._s(_vm.stockItemTitleInfo))]):_vm._e(),(_vm.stockItemTitleError)?_c('h5',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.stockItemTitleError)+" ")]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"pt-1 pb-2"},[(_vm.loading)?_c('font-awesome-icon',{staticClass:"fa-spin",attrs:{"far":"","icon":['fad', 'spinner']}}):_vm._e()],1):_vm._e(),(!_vm.loading && _vm.stockItemInfo)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.stockItemInfo)}}):_vm._e(),(_vm.stock.HasStock && _vm.stock.MaxPerCustomer)?_c('div',[_c('b-row',[_c('b-col',{staticClass:"amount pt-1 pb-2",attrs:{"cols":"12"}},[(_vm.stock.MaxPerCustomerLeft && _vm.openOnStartDate && _vm.openOnEndDate)?_c('div',{staticClass:"form-group amount d-flex"},[_c('div',[_c('b-button',{attrs:{"disabled":_vm.addToCartLoading || _vm.amount < 2,"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.changeAmount({
                  amount: _vm.amount - 1,
                  maxStock: _vm.stock.MaxPerCustomerLeft || 0
                })}}},[_c('font-awesome-icon',{attrs:{"far":"","icon":['fa', 'minus']}})],1),_c('span',{staticClass:"pl-2 pr-2"},[_vm._v(_vm._s(_vm.amount))]),_c('b-button',{attrs:{"disabled":_vm.addToCartLoading || _vm.stock.MaxPerCustomerLeft === _vm.amount,"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.changeAmount({
                  amount: _vm.amount + 1,
                  maxStock: _vm.stock.MaxPerCustomerLeft || 99
                })}}},[_c('font-awesome-icon',{attrs:{"far":"","icon":['fa', 'plus']}})],1)],1),(_vm.amount > 0 && _vm.stock.MaxPerCustomerLeft && _vm.amount > 0)?_c('div',[_c('item-planner-item-price',{staticClass:"ml-2",attrs:{"amount":_vm.amount,"item-id":_vm.itemId}})],1):_vm._e()]):_vm._e()]),_c('b-col',{attrs:{"cols":"12"}},[(
            _vm.stock.MaxPerCustomerLeft &&
              _vm.openOnStartDate &&
              _vm.openOnEndDate &&
              _vm.amount > 0 &&
              _vm.loggedIn
          )?_c('b-button',{attrs:{"variant":"primary","block":"","disabled":_vm.addToCartLoading || !_vm.loggedIn},on:{"click":function($event){return _vm.addToCart()}}},[(!_vm.addToCartLoading && _vm.loggedIn)?_c('span',[_vm._v("In Winkelmandje")]):_vm._e(),(_vm.addToCartLoading)?_c('font-awesome-icon',{staticClass:"fa-spin",attrs:{"far":"","icon":['fad', 'spinner']}}):_vm._e()],1):_vm._e()],1)],1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }