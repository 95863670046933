<template>
  <div v-if="stock && loaded" class="item-planner-single pt-2">
    <h5 v-if="stockItemTitleInfo">{{ stockItemTitleInfo }}</h5>
    <h5 v-if="stockItemTitleError" class="text-danger">
      {{ stockItemTitleError }}
    </h5>
    <div v-if="loading" class="pt-1 pb-2">
      <font-awesome-icon
        v-if="loading"
        class="fa-spin"
        far
        :icon="['fad', 'spinner']"
      />
    </div>
    <p v-if="!loading && stockItemInfo" v-html="stockItemInfo" />
    <div v-if="stock.HasStock && stock.MaxPerCustomer">
      <b-row>
        <b-col cols="12" class="amount pt-1 pb-2">
          <div
            v-if="stock.MaxPerCustomerLeft && openOnStartDate && openOnEndDate"
            class="form-group amount d-flex"
          >
            <div>
              <b-button
                :disabled="addToCartLoading || amount < 2"
                size="sm"
                variant="secondary"
                @click="
                  changeAmount({
                    amount: amount - 1,
                    maxStock: stock.MaxPerCustomerLeft || 0
                  })
                "
              >
                <font-awesome-icon far :icon="['fa', 'minus']" />
              </b-button>

              <span class="pl-2 pr-2">{{ amount }}</span>

              <b-button
                :disabled="
                  addToCartLoading || stock.MaxPerCustomerLeft === amount
                "
                size="sm"
                variant="secondary"
                @click="
                  changeAmount({
                    amount: amount + 1,
                    maxStock: stock.MaxPerCustomerLeft || 99
                  })
                "
              >
                <font-awesome-icon far :icon="['fa', 'plus']" />
              </b-button>
            </div>
            <div v-if="amount > 0 && stock.MaxPerCustomerLeft && amount > 0">
              <item-planner-item-price
                class="ml-2"
                :amount="amount"
                :item-id="itemId"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            v-if="
              stock.MaxPerCustomerLeft &&
                openOnStartDate &&
                openOnEndDate &&
                amount > 0 &&
                loggedIn
            "
            variant="primary"
            block
            :disabled="addToCartLoading || !loggedIn"
            @click="addToCart()"
          >
            <span v-if="!addToCartLoading && loggedIn">In Winkelmandje</span>
            <font-awesome-icon
              v-if="addToCartLoading"
              class="fa-spin"
              far
              :icon="['fad', 'spinner']"
            />
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { addToCart, getCart } from '@/services/CartService'
import { getClosedDates } from '@/services/WarehouseService'
import ItemPlannerItemPrice from '@/views/Item/ItemPlannerItemPrice'

import { mapGetters } from 'vuex'

import { getItemStock } from '@/services/ItemService'

import dayjs from 'dayjs'

import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export default {
  components: {
    ItemPlannerItemPrice
  },
  props: {
    itemId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      stock: null,
      addToCartLoading: false,
      alternativePrice: 0,
      amount: 1,
      openOnStartDate: false,
      openOnEndDate: false,
      loaded: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      selectedWarehouse: 'rentalInformation/warehouse',
      startDate: 'rentalInformation/startDate',
      endDate: 'rentalInformation/endDate'
    }),
    loggedIn: function() {
      const user = this.$store.getters.user
      if (user.Email) return true
      return false
    },
    stockItemTitleError: function() {
      if (!this.openOnStartDate || !this.openOnEndDate)
        return 'Deze uitleenpost is gesloten op een start/eind datum.'

      if (this.stock.HasStock && this.stock.MaxPerCustomerLeft < 1)
        return 'U heeft het maximaal toegestane aantal van dit artikel bereikt'
      if (!this.stock.HasStock)
        return `Dit product is niet beschikbaar in ${this.selectedWarehouse}`

      return null
    },
    stockItemTitleInfo: function() {
      if (this.stock.MaxCustomerStock && this.stock.HasStock)
        return `Beschikbaar in ${this.stock.WarehouseID}`
      return null
    },
    stockItemInfo: function() {
      if (!this.loggedIn) return ''
      if (this.stock.NextStartDate)
        return `Wel beschikbaar vanaf ${dayjs(this.stock.NextStartDate).format(
          'DD/MM/YYYY'
        )}`

      if (this.stock.NextEndDate)
        return `Wel beschikbaar met een terugbrengdatum vanaf ${dayjs(
          this.stock.NextEndDate
        ).format('DD/MM/YYYY')}`
      if (
        this.stock.MaxPerCustomerLeft &&
        this.openOnStartDate &&
        this.openOnEndDate &&
        this.stock.HasStock
      )
        return `${
          this.stock.MaxPerCustomerLeft
            ? Math.round(this.stock.MaxPerCustomerLeft)
            : '0'
        } stuks op voorraad.`

      if (this.stock.NextStock && this.stock.HasStock === false)
        return `Wel beschikbaar vanaf ${dayjs(this.stock.NextStock).format(
          'DD/MM/YYYY'
        )}`

      return null
    },

    getAlternativeReturnPrice: function() {
      if (dayjs(this.endDate).weekday(4)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    startDate() {
      this.updateClosedDates(this.startDate, this.endDate)
      this.getItemStock()
    },
    endDate() {
      this.updateClosedDates(this.startDate, this.endDate)
      this.getItemStock()
    },
    selectedWarehouse() {
      this.updateClosedDates(this.startDate, this.endDate)
      this.getItemStock()
    },
    stock: async function() {
      if (this.stock.MaxPerCustomerLeft > 0) {
        this.amount = 1
      } else {
        this.amount = 0
      }
    }
  },
  async created() {
    if (this.selectedWarehouse) {
      await this.getItemStock()
    }
    if (this.selectedWarehouse) {
      await this.updateClosedDates(this.startDate, this.endDate)
    }

    this.loaded = true
  },
  methods: {
    async updateClosedDates(startDate, endDate) {
      const closedDates = await getClosedDates({
        startDate,
        endDate,
        stock: this.selectedWarehouse
      })

      this.openOnStartDate = !closedDates.includes(startDate)
      this.openOnEndDate = !closedDates.includes(endDate)
    },
    changeAmount: function({ amount, maxStock }) {
      if (amount > 0) {
        this.amount = Math.min(amount, maxStock)
      }
    },
    addToCart: async function(amount) {
      this.addToCartLoading = true
      let itemID = null
      let compositionID = null

      if (!amount) {
        amount = this.amount
      }

      if (this.type == 'Composition') {
        compositionID = this.itemId
      } else {
        itemID = this.itemId
      }

      await addToCart({
        itemID,
        compositionID,
        startDate: this.startDate,
        endDate: this.endDate,
        warehouse: this.selectedWarehouse,
        amount: amount
      })
      this.$emit('updateItemData')
      this.amount = 1
      await getCart()
      this.$toasted.show(
        `Artikel ${this.description} toegevoegd aan winkelwagen`
      )

      this.addToCartLoading = false
    },
    async getItemStock() {
      this.loading = true

      const stockData = await getItemStock({
        itemID: this.itemId,
        startDate: this.startDate,
        endDate: this.endDate,
        warehouse: this.selectedWarehouse
      })
      this.stock = stockData?.Warehouses[0] ?? null

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss"></style>
