<template>
  <div>
    <font-awesome-icon
      v-if="loading"
      class="fa-spin"
      far
      :icon="['fad', 'spinner']"
    />
    <span v-if="priceObject !== null && !loading">
      &euro;
      {{ price }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getItemPrice } from '@/services/ItemService'

export default {
  name: 'ItemPlannerItemPrice',
  props: {
    itemId: {
      type: String,
      required: true
    },
    amount: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      priceObject: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      startDate: 'rentalInformation/startDate',
      endDate: 'rentalInformation/endDate',
      warehouse: 'rentalInformation/warehouse'
    }),
    price() {
      if (this.priceObject === null) return '0,00'
      const price = this.priceObject.PriceIncVAT * this.amount + Number.EPSILON
      const roundedPrice = Math.round(price * 100) / 100
      const fixedPrice = roundedPrice.toFixed(2)

      return fixedPrice.toString().replace('.', ',')
    }
  },
  watch: {
    endDate() {
      this.getItemPrice()
    },
    warehouse() {
      this.getItemPrice()
    }
  },
  created() {
    this.getItemPrice()
  },
  methods: {
    async getItemPrice() {
      this.loading = true
      this.priceObject = await getItemPrice({
        itemId: this.itemId,
        startDate: this.startDate,
        endDate: this.endDate,
        warehouse: this.warehouse
      })
      this.loading = false
    }
  }
}
</script>

<style></style>
