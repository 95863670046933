<template>
  <div class="item-image">
    <div class="item-image-slider mb-4">
      <agile
        ref="main"
        class="main"
        :options="options"
        :as-nav-for="asNavFor1"
        @after-change="updateIndex"
      >
        <div
          v-for="(image, imageIndex) in images"
          :key="imageIndex"
          class="slide"
          :class="`slide--${imageIndex}`"
          @click="galleryIndex = imageIndex"
        >
          <div class="zoom-icon" @click="galleryIndex = imageIndex">
            <font-awesome-icon icon="search-plus" />
          </div>
          <img
            :alt="image.DefaultAltTag"
            :src="`${appURL}${image}&Format=Standard`"
          />
        </div>

        <template slot="prevButton"
          ><font-awesome-icon icon="chevron-left"
        /></template>
        <template slot="nextButton"
          ><font-awesome-icon icon="chevron-right"
        /></template>
      </agile>

      <gallery
        :images="slides"
        :index="galleryIndex"
        @close="galleryIndex = null"
      ></gallery>
    </div>
    <div class="item-image-thumbs">
      <div class="slide-previews">
        <div v-for="(image, index) in thumbnails" :key="image">
          <img
            v-if="images.length > 6 && image.index == 5"
            class="overlayed"
            :src="`${appURL}${image}&Format=Standard`"
          />
          <img
            v-else
            :src="`${appURL}${image}&Format=Standard`"
            @click="$refs.main.goTo(index)"
          />
          <div
            v-if="images.length > 6 && index == 5"
            class="overlay"
            @click="galleryIndex = parseInt(index)"
          >
            + {{ images.length - 6 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueAgile } from 'vue-agile'
import Gallery from 'vue-gallery'

export default {
  components: {
    agile: VueAgile,
    Gallery
  },
  props: {
    images: {
      required: true,
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: function() {
    return {
      currentSlide: 0,
      galleryIndex: null,
      indexedImages: [],
      asNavFor1: [],
      asNavFor2: [],
      options: {
        dots: false,
        fade: true,
        navButtons: false,
        responsive: [
          {
            breakpoint: 600
          },
          {
            breakpoint: 600,
            settings: {
              navButtons: true
            }
          }
        ]
      }
    }
  },
  computed: {
    thumbnails() {
      return this.images.slice(0, 6)
    },
    appURL() {
      return process.env.VUE_APP_URL
    },
    slides() {
      return this.images.map(image => `${this.appURL}${image}&Format=Standard`)
    }
  },
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails)
    this.asNavFor2.push(this.$refs.main)
  },
  methods: {
    updateIndex(event) {
      this.currentSlide = event.currentSlide
    }
  }
}
</script>
<style lang="scss">
.slide-previews {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  justify-content: center;

  > div {
    position: relative;
    margin: 10px;
    max-width: 80px;
  }

  img.overlayed {
    filter: brightness(70%);
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: 0.5s ease;
    background-color: transparent;
    margin: 0;
    cursor: pointer;
    color: white;
    font-weight: 700;
    text-align: center;
    align-items: center;
    display: flex;
    font-size: 27px;
    justify-content: center;
  }
}
.slide-previews img {
  width: 100%;
  height: auto;

  border-radius: 4px;
  cursor: pointer;
}
.zoom-icon {
  position: absolute;
  bottom: 10px;
  right: 180px;
  color: #ccc;
  z-index: 300;
  cursor: pointer;
  font-size: 24px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.zoom-icon:hover {
  color: #6c757d;
}

@media screen and (max-width: 1199px) {
  .zoom-icon {
    bottom: 5px;
    right: 120px;
  }
}
@media screen and (max-width: 991px) {
  .zoom-icon {
    bottom: 5px;
    right: 65px;
  }
}

@media screen and (max-width: 550px) {
  .zoom-icon {
    bottom: 5px;
    right: 40px;
  }
}

.slide {
  height: 100%;
}

.item-image {
  height: auto !important;
}
.item-image-slider {
  max-height: 400px;
  height: 400px;
}
.main {
  margin-bottom: 30px;
}

.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
}

.slide.slide--0.agile__slide.agile__slide--current.agile__slide--active {
  height: 100% !important;
}
.agile {
  height: 100%;
}
.agile__list {
  height: 100%;
  width: auto !important;
}
.agile__slides {
  height: 100%;
}
.agile__track {
  height: 100%;
}
.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.agile__nav-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 30px;
}
.agile__nav-button--prev {
  left: 0px;
}
.agile__nav-button--next {
  right: 0px;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #888;
}

.slide {
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}
.slide--thumbniail {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  height: 100%;
}
</style>
